<template>
  <TopicsTable
    :topics="topics"
    :loading="loading"
  >
    <template #topActions>
      <TTBtn
        :to="{
          name : Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый топик
      </TTBtn>
      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить топик
          </VCardTitle>

          <VCardText>
            Топик <span class="red--text">{{ topic.name }}</span> будет удален! <br>
            Действительно хотите удалить топик?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <TTBtn
              text
              @click="handleCancel"
            >
              Нет
            </TTBtn>

            <TTBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </TTBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <TTBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_VIEW,
              params : {
                testId : testId,
                topicId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </TTBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <TTBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_INDEX,
              params : {
                testId : testId,
                topicId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-list
            </VIcon>
          </TTBtn>
        </template>

        <span>Уровни</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <TTBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_TOPICS_EDIT,
              params : {
                testId : testId,
                topicId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </TTBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <TTBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteTopic(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </TTBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </TopicsTable>
</template>

<script>
import TopicsTable from './TopicsTable.vue';

export default {
  name: 'TopicsTableContainer',
  components: {
    TopicsTable,
  },
  inject: ['Names'],
  data() {
    return {
      loading: false,
      confirmDialog: false,
      topic: {},
      topics: [],
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },
  },
  async created() {
    await this.fetch();
  },
  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { testId } = this;
        const data = { testId };

        const { topics } = await this.$di.api.MultiLevelTest.TopicsByTest({ data });

        this.topics = topics;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.topic;
        const data = { id };

        await this.$di.api.MultiLevelTest.TopicsDelete({ data });

        this.resetTopicEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },
    deleteTopic(item) {
      this.confirmDialog = true;
      this.topic = { ...item };
    },
    resetTopicEdit() {
      this.confirmDialog = false;
      this.level = {};
    },
    handleCancel() {
      this.resetTopicEdit();
    },
  },
};
</script>
