<template>
  <TTView>
    <VRow>
      <VCol>
        <TopicsTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TopicsTableContainer from '../../../components/multi-level-test/topics/TopicsTableContainer.vue';

export default {
  name: 'TestTopics',

  components: {
    TopicsTableContainer,
  },
};
</script>
